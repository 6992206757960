import { useEffect } from "react";
import "../styles/AboutUs.css";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="aboutus-page-container">
      <div className="aboutus-header">
        <div className="aboutus-header-text">
          <h1>
            <span>About </span>
            <span>DR Promotions</span>
          </h1>
        </div>
      </div>
      <div className="aboutus-content">
        <div className="column">
          <h2>HOW IT STARTED</h2>
          <p>
            DR Promotions was founded in 2023, with the goal to provide the highest
            level of services and transparency to distilleries focused on growing
            their brands in South Carolina, North Carolina and Virginia.
          </p>
        <div className="column">
          <h2>EVENTS</h2>
          <p>
             <a href="javascript:void( window.open( 'https://form.jotform.com/241815533377157', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) "> Fayetteville Carrabba&#039;s Cocktail Dinner and Auction for Alzheimer’s </a>
          </p>
        </div>
       </div>      
      </div>
    </div>
  );
}
